<template>
  <!-- 我们的合作伙伴 -->
  <div
    class="partnerBox"
    ref="partnerBox"
    v-if="container.length !== 0"
    :style="setBackground()"
  >
    <h3>{{ container.Partner.title }}</h3>
    <p class="pStyle" v-html="container.Partner.desc"></p>
    <div class="itemBox">
      <div
        class="imageBox"
        v-for="(item, index) in container.Partner.items"
        :key="index"
      >
        <img v-lazy="item.image" class="image" />
      </div>
    </div>
    <!-- <div class="buttonStyle">
      更多合作伙伴 <img :src="moreImg.src" alt="" />
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      // moreImg: { src: require("../../assets/img/All/more.png") },
    };
  },
  computed: {
    ...mapState(["container"]),
  },

  mounted() {
    // console.log(this.$store.container);
  },
  methods: {
    setBackground() {
      // return `background-image:${this.$store.state.container.Partner.coverPic}`
      // tis.$refs.partnerBox.background-image=`${container.Partner.coverPic}`
    },
  },
};
</script>
<style lang="less" scoped>
.partnerBox {
  width: 100%;
  padding: 0 5%;

  .itemBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    margin-bottom: 3.125rem;

    .imageBox {
      cursor: pointer;
      width: 18.75rem;
      height: 9.375rem;
      margin-right: 1rem;
      margin-bottom: 2rem;

      .image {
        width: 100%;
        max-width: 18.75rem;
        height: 100%;
      }
    }
  }
}

/* @media screen and (max-width: 626px) {
    .imageBox {
      width: 10.75rem !important;
      height: 6.375rem !important;
    }
  } */
@media screen and (max-width: 560px) {
  .imageBox {
    width: 10.75rem !important;
    height: 6.375rem !important;
  }
}

@media (min-width: 560px) and (max-width: 626px) {
  .imageBox {
    width: 12.75rem !important;
    height: 6.875rem !important;
  }
}

@media (min-width: 1616px) and (max-width: 1780px) {
  .imageBox {
    width: 16rem !important;
    height: 7.5rem !important;
  }
}

@media (min-width: 627px) and (max-width: 1615px) {
  .imageBox {
    width: 14rem !important;
    height: 7rem !important;
  }
}
</style>
