<template>
  <!-- 主营业务 -->
  <div class="business">
    <div class="businessBox" v-if="container.length !== 0">
      <h3>{{ container.Business.title }}</h3>
      <p class="pStyle" v-html="container.Business.desc"></p>

      <div class="itemBox">
        <el-row :gutter="40">
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in container.Business.items"
            :key="index">
            <a :href="item.url" target="_blank">
              <el-card>
                <img v-lazy="item.image" class="image" />
                <div style="padding: 1.5rem 0">
                  <h4>{{ item.name }}</h4>
                  <div class="bottom clearfix" v-html="item.info">
                    <!-- <p class="pCenter">{{ item.info }}</p> -->
                  </div>
                </div>
              </el-card>
            </a>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState(["container"]),
    },
  };
</script>
<style lang="less" scoped>
  .businessBox {
    width: 100%;
    margin-top: 2rem;
    padding: 0 5.2083% 6.25% 5.2083%;

    /deep/.el-card {
      cursor: pointer;
      margin-bottom: 1.5rem;

      .el-card__body {
        padding: 0 !important;
      }

      .image {
        width: 100%;
        /* max-width: 24.375rem; */
        height: 100%;
        transition: all 1s;

        &:hover {
          transform: scale(1.05);
          opacity: 0.9;
        }
      }
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    .businessBox {
      margin-top: 3.5rem;
    }
  }

  @media screen and(max-width:501px) {
    .businessBox {
      margin-top: 7rem;

      h4 {
        font-size: 1rem !important;
        margin-bottom: 0 !important;
      }

      .pStyle {
        margin-bottom: 1.56rem !important;
      }
    }
  }
</style>