<template>
  <!-- 我们的优势 -->
  <div class="advantageBox" v-if="container.length !== 0">
    <h3>{{ container.Advantage.title }}</h3>
    <p class="pStyle" v-html="container.Advantage.desc"></p>
    <div class="itemBox">
      <el-row :gutter="25">
        <el-col
          :xs="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
          v-for="(item, index) in container.Advantage.items"
          :key="index"
        >
          <div :body-style="{ padding: '0px' }">
            <div class="circle">
              <img v-lazy="item.image" alt="" />
            </div>
            <div style="padding: 1.25rem 0">
              <h4>{{ item.name }}</h4>
              <div class="bottom">
                <p class="pCenter" v-html="item.info"></p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["container"]),
  },
};
</script>
<style lang="less" scoped>
.advantageBox {
  width: 100%;
  min-height: 40rem;
  /* padding: 0 12.75rem; */
  padding: 0 10.41%;
  box-sizing: border-box;
  background: url("../../assets/img/All/advantage.jpg");

  .circle {
    margin: 0 auto;
    background: #1997e3;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;

    img {
      width: 100%;
      max-width: 10rem;
      height: 100%;
      transition: all 1s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

@media screen and (max-width: 501px) {
  .advantageBox {
    padding: 0 3%;

    .pStyle {
      margin-bottom: 1.75rem;
    }

    .itemBox {
      width: 100% !important;

      h4 {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .advantageBox {
    padding: 0 8.41%;

    .pStyle {
      margin-bottom: 1.75rem;
    }

    .itemBox {
      width: 100% !important;

      h4 {
        font-size: 1.15rem;
      }

      .circle {
        width: 6.25rem;
        height: 6.25rem;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .advantageBox {
    padding: 0 5%;

    .pStyle {
      margin-bottom: 1.75rem;
    }

    .circle {
      width: 8.25rem;
      height: 8.25rem;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .advantageBox {
    padding: 0 5%;

    .circle {
      width: 8.25rem;
      height: 8.25rem;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1425px) {
  .advantageBox {
    padding: 0 9%;
  }
}
</style>
