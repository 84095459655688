<template>
  <!-- 走马灯 轮播图 -->
  <div style="position: relative">
    <div class="carouselBox" v-if="container.length !== 0">
      <el-carousel :interval="5000" trigger="click" :height="bannerH + 'px'">
        <el-carousel-item
          v-for="(item, index) in container.Banner.items"
          :key="index"
        >
          <a v-if="item.url" :href="item.url" target="_blank">
            <img :src="item.image" class="bannerImg"/>
          </a>
          <template v-else>
            <img :src="item.image" class="bannerImg"/>
          </template>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="myOrder">
      <myOrder></myOrder>
    </div>
  </div>
</template>

<script>
import myOrder from "../OrderQuery/OrderQuery";
import { mapState } from "vuex";
export default {
  components: {
    myOrder,
  },
  data() {
    return {
      bannerH: 700,
    };
  },
  computed: {
    ...mapState(["container"]),
  },
  methods: {
    setBannerH() {
      var imgHeight = document.body.clientWidth / 2.74;
      if (imgHeight < 200) {
        this.bannerH = 200;
      } else {
        this.bannerH = imgHeight;
      }
    },
  },
  mounted() {
    this.setBannerH();
    window.addEventListener(
      "resize",
      () => {
        this.setBannerH();
      },
      false
    );
  },
};
</script>
<style lang="less">
.myOrder {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -13%;
  z-index: 2;
}

.carouselBox {
  position: relative;

  .el-carousel__container {
    /* height: 43.75rem; */
    /* position: relative; */
    .el-carousel__item {
      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .el-carousel__indicators--horizontal {
    position: absolute;
    bottom: 20%;
  }

  // 底部状态的切换按钮
  .el-carousel__button {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 2px solid #eee;
    background-color: transparent;
  }

  // 活动的切换按钮
  .is-active {
    .el-carousel__button {
      background-color: #1997e3;
      border: 1px solid #1997e3;
    }
  }

  .el-carousel__arrow {
    width: 7.6875rem;
    height: 19rem;
    border-radius: 0;

    &:hover {
      background-color: transparent !important;
    }

    i {
      display: none;
    }
  }

  /* 右边按钮 */
  .el-carousel__arrow--right {
    right: 0;
    background: url("../../assets/img/All/right.png") no-repeat;
    background-size: 100%;

    &:hover {
      opacity: 0.8;
    }
  }

  /* 左边按钮 */
  .el-carousel__arrow--left {
    left: 0;
    background: url("../../assets/img/All/left.png") no-repeat;
    background-size: 100%;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .myOrder {
    bottom: -16%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .myOrder {
    bottom: -18%;
  }

  .carouselBox {
    .el-carousel__arrow {
      width: 6.6875rem !important;
      height: 14rem !important;
    }

    .el-carousel__container {
      height: 33.75rem;
    }
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .myOrder {
    bottom: -21%;
  }

  .carouselBox {
    .el-carousel__arrow {
      width: 5.6875rem !important;
      height: 12rem !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .myOrder {
    bottom: -25%;
  }

  .carouselBox {
    .el-carousel__arrow {
      width: 4.6875rem !important;
      height: 10rem !important;
    }
  }
}

@media screen and (max-width: 501px) {
  .myOrder {
    bottom: -57%;
  }

  .carouselBox {
    .el-carousel__arrow {
      width: 3.6875rem !important;
      height: 8rem !important;
    }

    .el-carousel__indicators--horizontal {
      bottom: 0;
    }
  }
}
</style>
