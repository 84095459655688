<template>
  <!-- 订单盒子 -->
  <div class="orderBox" v-if="container.Shortcut">
    <!-- 订单输入框 -->
    <div class="orderInput">
      <span style="white-space: nowrap">{{
          langData["index.label.query"]
        }}</span>

      <form style="width: 100%" target="_blank" action="/track">
        <el-input
            class="inputClass"
            v-model="orderInput"
            name="waybillNum"
            auto-complete="off"
            :placeholder="langData['index.input.waybillNum']"
        >
          <!-- 查询按钮 -->
          <el-button slot="suffix" type="text" native-type="submit">
            {{ langData["index.btn.query"] }}
          </el-button>
        </el-input>
      </form>
    </div>
    <div class="orderOther">
      <a
          v-for="(item, index) in container.Shortcut.items"
          :key="index"
          class="orderChild"
          :href="item.url"
          target="_blank"
      >
        <div class="orderImg"><img :src="item.image" alt=""/></div>
        <span> {{ item.name }}</span>
      </a>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      // 订单input框
      orderInput: "",
    };
  },
  computed: {
    ...mapState(["langData", "container"]),
  },
};
</script>
<style lang="less" scoped>
.orderBox {
  width: 89.58%;
  height: 10.9375rem;
  margin: 0 auto;
  display: flex;
  background: #1997e3;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  // 订单查询input框
  .orderInput {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 1.25rem;

    span {
      display: inline-block;
      font-size: 1rem;
      font-weight: bold;
      color: white;
    }

    /* input输入框样式 */

    /deep/ .inputClass {
      font-size: 1rem;

      .el-input__inner {
        height: 3.75rem;
      }

      .el-input__suffix {
        display: flex;
        align-items: center;
      }

      .el-button--text {
        font-size: 1.125rem;
      }
    }

    button {
      margin-right: 1.25rem;
      font-weight: bold;
      color: #fe9a02ed;

      &:hover {
        cursor: pointer;
      }
    }
  }

  // 其他订单
  .orderOther {
    width: 50%;
    box-sizing: border-box;
    padding: 0 3.125%;
    /* padding-left: 6%; */
    display: flex;
    justify-content: space-between;

    .orderChild {
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;

      .orderImg {
        width: 6.25rem;
        height: 6.25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 1365px) and (max-width: 1440px) {
  .orderImg {
    width: 5.5rem !important;
    height: 5.5rem !important;
  }
}

@media (min-width: 1025px) and (max-width: 1365px) {
  .orderImg {
    width: 5rem !important;
    height: 5rem !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .orderBox {
    /* height: 12.5rem; */
    flex-wrap: wrap;
  }

  .orderOther {
    width: 100% !important;

    .orderImg {
      width: 5rem !important;
      height: 5rem !important;
      // img {
      //   width: 120% !important;
      // }
    }
  }

  .orderInput {
    width: 100% !important;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .orderBox {
    height: 8.375rem;
    flex-wrap: wrap;
  }

  .orderOther {
    width: 100% !important;
    padding-bottom: 1.25rem;

    .orderImg {
      width: 4.5rem !important;
      height: 4.5rem !important;

      img {
        width: 100% !important;
      }
    }
  }

  .orderInput {
    width: 100% !important;
    padding: 0.8rem 1.25rem 0 1.25rem !important;

    span {
      display: none !important;
    }

    /deep/ .inputClass {
      font-size: 1.125rem;

      .el-input__inner {
        height: 3.125rem !important;
      }
    }

    button {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 501px) {
  .orderBox {
    height: 7.575rem;
    flex-wrap: wrap;

    .orderOther {
      width: 100% !important;
      padding-bottom: 0;
      padding: 0;

      .orderImg {
        width: 4rem !important;
        height: 4rem !important;

        img {
          width: 100% !important;
        }
      }
    }

    .orderInput {
      width: 100% !important;
      padding: 0.5rem;

      span {
        display: none !important;
      }

      /deep/ .inputClass {
        font-size: 1rem;

        .el-input__inner {
          height: 2.125rem !important;
        }
      }

      button {
        margin: 0 !important;
      }
    }
  }
}
</style>
