<template>
  <div class="home">
    <!-- 轮播组件 -->
    <div class="myCarousel">
      <myCarousel></myCarousel>
    </div>
    <!-- 订单查询 -->
    <div class="myOrder">
      <!-- <myOrder></myOrder> -->
    </div>
    <!-- 主营业务 -->
    <div class="myBusiness">
      <myBusiness></myBusiness>
    </div>
    <!-- 我们的优势 -->
    <div class="myAdvantage">
      <myAdvantage></myAdvantage>
    </div>
    <!-- 我们的合作伙伴 -->
    <div class="myPartner">
      <myPartner></myPartner>
    </div>
  </div>
</template>
<script>
// 轮播组件
import myCarousel from "../components/Carousel/Carousel";
// 主营业务
import myBusiness from "../components/Business/Business";
// 我们的优势
import myAdvantage from "../components/Advantage/Advantage";
// 我们的合作伙伴
import myPartner from "../components/Partner/Partner";
// 导入axios
import axios from "../assets/axios/axios";
// import { mapState } from "vuex";
export default {
  name: "Home",
  components: {
    // 轮播组件
    myCarousel,
    // 订单查询
    // myOrder,
    // 主营业务
    myBusiness,
    // 我们的优势
    myAdvantage,
    // 我们的合作伙伴
    myPartner,
  },
  data() {
    return {
      p: null,
    };
  },
  created() {
    this.getContainer();
    this.getReferer();
  },
  methods: {
    // 获取主页内容
    getContainer() {
      axios.getContainer(this.p).then((res) => {
        const { data } = res.data;
        this.$store.commit("getContainer", data);
      });
    },
    getReferer() {
      this.p = this.$route.query.p || "";
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  position: relative;
}
</style>
